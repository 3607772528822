<template>
  <div
    :class="{
      cui__layout__grayBackground: settings.isGrayBackground,
    }"
  >
    <div
      style="
        position: fixed;
        right: -40px;
        top: 50%;
        z-index: 99;
        transform: rotate(90deg);
        padding-left: -100px;
      "
    >
      <a-button type="normal" @click="showDrawer" style="height: 50px">
        <br />
        <a-icon type="notification" />Feedback
      </a-button>
    </div>

    <a-drawer
      :title="$t('feedback.sendFeedback')"
      :width="720"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row>
          <a-col>
            <a-form-item :label="$t('feedback.category')">
              <a-checkbox-group
                v-model="feedback.categories"
                @change="
                  (a) => {
                    feedback.categories = a;
                  }
                "
                :options="[
                  $t('feedback.comment'),
                  $t('feedback.problem'),
                  $t('feedback.idea'),
                  $t('feedback.others'),
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          :gutter="16"
          v-if="feedback.categories.includes($t('feedback.problem'))"
        >
          <a-col :span="12">
            <a-form-item :label="$t('feedback.section')">
              <a-select
                @change="
                  (a) => {
                    feedback.section = a;
                  }
                "
                v-decorator="[
                  'section ',
                  {
                    rules: [
                      { required: true, message: $t('feedback.selectSection') },
                    ],
                  },
                ]"
                :placeholder="$t('feedback.selectSection')"
              >
                <template v-for="(item, index) in menuData">
                  <a-select-option
                    :value="$t(item.title)"
                    :key="index"
                    v-if="!item.category"
                  >
                    {{ $t(item.title) }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('feedback.priority')">
              <a-select
                @change="
                  (a) => {
                    feedback.priority = a;
                  }
                "
                v-decorator="[
                  'priority',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('feedback.selectPriority'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('feedback.selectPriority')"
              >
                <a-select-option value="low">
                  {{ $t("feedback.low") }}
                </a-select-option>
                <a-select-option value="medium">
                  {{ $t("feedback.medium") }}
                </a-select-option>
                <a-select-option value="high">
                  {{ $t("feedback.high") }}
                </a-select-option>
                <a-select-option value="critical">
                  {{ $t("feedback.critical") }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col>
            <a-form-item :label="$t('feedback.message')">
              <a-textarea
                v-model="feedback.desc"
                placeholder="Message"
                v-decorator="[
                  'desc',
                  {
                    rules: [{ required: true, message: $t('requis.message') }],
                  },
                ]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col>
            <a-form-item :label="$t('feedback.uploadImages')">
              <a-upload-dragger
                accept=".jpeg,.jpg,.png,.pdf"
                :customRequest="uploadImage"
                multiple
                :remove="removeFile"
                :before-upload="beforeUploadFiles"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  {{ $t("actualite.cliquer") }}
                </p>
              </a-upload-dragger>
              <a-progress
                class="mb-4"
                :stroke-color="{
                  from: '#108ee9',
                  to: '#87d068',
                }"
                v-show="uploadPercentage > 0"
                :percent="uploadPercentage"
                status="active"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-item :label="$t('feedback.uploadAudio')">
              <div class="d-flex flex-center mb-7">
                <div class="mt-2">
                  <a-button
                    class="btn btn-info btn-sm"
                    type="primary"
                    @click="onEnable"
                    v-if="!recorder"
                  >
                    <a-icon type="audio" /> {{ $t("feedback.record") }}
                  </a-button>

                  <a-button
                    @click="
                      () => {
                        timer = moment(0);
                        onStart();
                      }
                    "
                    v-if="!isStarted && recorder"
                    size="large"
                    shape="circle"
                    ><a-icon type="audio"
                  /></a-button>

                  <a-button
                    @click="onStop"
                    v-if="isStarted"
                    size="large"
                    type="danger"
                    shape="circle"
                    ><a-icon type="check" class="pt-2"
                  /></a-button>

                  <span class="pl-1 pt-3" v-if="isStarted">
                    {{ counter }}
                  </span>
                </div>
                <br />
                <br />

                <div
                  v-if="stream && !isStarted"
                  style="margin-left: 10px; margin-right: 3px"
                >
                  <audio controls="true" :src="stream"></audio>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          :loading="feedbackLoading"
          :disabled="feedbackLoading"
          type="primary"
          @click="
            () => {
              sendFeedback();
            }
          "
        >
          {{ $t("feedback.sendFeedback") }}
        </a-button>
      </div>
    </a-drawer>

    <a-layout
      :class="{
        cui__layout__contentMaxWidth: settings.isContentMaxWidth,
        cui__layout__appMaxWidth: settings.isAppMaxWidth,
        cui__layout__grayBackground: settings.isGrayBackground,
        cui__layout__squaredBorders: settings.isSquaredBorders,
        cui__layout__cardsShadow: settings.isCardShadow,
        cui__layout__borderless: settings.isBorderless,
      }"
    >
      <cui-sidebar />

      <!-- left menu -->
      <cui-menu-left
        v-if="settings.menuLayoutType === 'left' && !settings.isMobileView"
      />

      <!-- left menu mobile -->
      <div v-if="settings.isMobileView">
        <div :class="$style.handler" @click="toggleMobileMenu">
          <div :class="$style.handlerIcon"></div>
        </div>
        <a-drawer
          :closable="false"
          :visible="settings.isMobileMenuOpen"
          placement="left"
          :wrapClassName="$style.mobileMenu"
          @close="toggleMobileMenu"
        >
          <cui-menu-left />
        </a-drawer>
      </div>

      <!-- top menu -->
      <cui-menu-top
        v-if="settings.menuLayoutType === 'top' && !settings.isMobileView"
      />

      <a-layout>
        <a-layout-header
          :class="{
            cui__layout__header: true,
            cui__layout__fixedHeader: settings.isTopbarFixed,
            cui__layout__headerGray: settings.isGrayTopbar,
          }"
        >
          <cui-topbar />
        </a-layout-header>
        <cui-breadcrumbs v-if="this.$route.path != '/chat'" />
        <a-layout-content style="height: '100%'; position: 'relative'">
          <div class="cui__utils__content">
            <transition
              :name="settings.routerAnimation"
              mode="out-in"
              v-on:reload="reload"
              :key="reloads"
            >
              <router-view />
            </transition>
          </div>
        </a-layout-content>

        <footer-layout mainTheme></footer-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import CuiTopbar from "@/components/cleanui/layout/Topbar";
import CuiBreadcrumbs from "@/components/cleanui/layout/Breadcrumbs";
import CuiSidebar from "@/components/cleanui/layout/Sidebar";
import CuiMenuLeft from "@/components/cleanui/layout/Menu/MenuLeft";
import CuiMenuTop from "@/components/cleanui/layout/Menu/MenuTop";
import FooterLayout from "../FooterLayout.vue";
import moment from "moment";
import { getMenuData } from "@/services/menu";
import apiClient from "@/services/axios";
import store from "store";

class WebRecording {
  constructor() {
    this.constraints = { audio: true };
    this.chunks = [];
    this.chunkTime = 1000;
    this.recorder = null;
    this.isStoped = false;
    this.isReady = false;

    const mediaNavigator =
      window.navigator.getUserMedia ||
      window.navigator.mozGetUserMedia ||
      window.navigator.msGetUserMedia ||
      window.navigator.webkitGetUserMedia;
    if (!mediaNavigator) {
      throw Error("mediaNavigator not supported");
    }

    navigator.mediaDevices
      .getUserMedia(this.constraints)
      .then((stream) => {
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = (event) => {
          this.chunks.push(event.data);
          if (this.isReady) {
            this.chunkReadyHandler(event.data);
          }
        };
        this.recorder.onstop = () => {
          const recordStream = new Blob(this.chunks, {
            type: "audio/mp3;codecs=opus",
          });
          if (this.isStoped) {
            this.stopHandler(recordStream);
          }
        };
      })
      .catch((e) => {
        throw "web recording error: " + e.message;
      });
  }

  startRecord() {
    this.chunks = [];
    if (!this.recorder) {
      throw Error("MediaRecorder not available");
    }
    this.recorder.start(this.chunkTime);
  }

  stopRecord() {
    if (!this.recorder) {
      throw Error("MediaRecorder not available");
    }
    this.recorder.stop();
  }

  onStop(handler) {
    this.isStoped = true;
    this.stopHandler = handler;
  }

  onChunkReady(handler) {
    this.isReady = false;
    this.chunkReadyHandler = handler;
  }

  chunkReadyHandler(a) {}

  stopHandler(b) {}

  setChunkTime(timeMs) {
    this.chunkTime = timeMs;
  }
}

export default {
  name: "MainLayout",
  components: {
    CuiTopbar,
    CuiMenuLeft,
    CuiMenuTop,
    CuiBreadcrumbs,
    CuiSidebar,
    FooterLayout,
  },
  computed: {
    ...mapState(["settings", "user"]),
    counter() {
      return this.timer.format("mm:ss");
    },
    getBuilding() {
      return (
        this.settings.image.filter(
          (img) => img.db == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  async created() {
    // ask about all connection status
    this.$socket.client.emit("connectedStatus", {
      token: store.get("accessToken"),
      db: store.get("DB"),
    });

    // receive initial connections list
    this.$socket.client.on(
      "connectionList",
      async ({ admins, parents, teachers }) => {
        this.settings.connected.admins = admins;
        this.settings.connected.parents = parents;
        this.settings.connected.teachers = teachers;
      }
    );

    // set a listenner to connection status changer
    this.$socket.client.on("parent-status", async ({ type, parentId }) => {
      const newParents = this.someoneConnected(type, "parents", parentId);
      await this.$store.commit("settings/CHANGE_CONNECTED", {
        list: "parents",
        value: newParents,
      });
    });

    this.$socket.client.on("admin-status", async ({ type, adminId }) => {
      const newAdmins = this.someoneConnected(type, "admins", adminId);
      await this.$store.commit("settings/CHANGE_CONNECTED", {
        list: "admins",
        value: newAdmins,
      });
    });
    this.$socket.client.on("teacher-status", async ({ type, teacherId }) => {
      const newTeachers = this.someoneConnected(type, "teachers", teacherId);
      await this.$store.commit("settings/CHANGE_CONNECTED", {
        list: "teachers",
        value: newTeachers,
      });
    });
  },

  data: function () {
    return {
      uploadPercentage: 0,
      touchStartPrev: 0,
      touchStartLocked: false,
      reloads: 0,
      form: this.$form.createForm(this),
      visible: false,
      recorder: null,
      stream: null,
      isStarted: false,
      show: true,
      timer: moment(),
      feedback: {
        categories: [],
        uploadFiles: [],
      },
      menuData: getMenuData,
      feedbackLoading: false,
    };
  },
  mounted() {
    this.bindMobileSlide();
    this.detectViewPort(true);
    window.addEventListener("resize", this.detectViewPortListener);

    setInterval(() => {
      if (this.isStarted) this.timer = moment(this.timer.add(1, "seconds"));
    }, 1000);
  },
  beforeDestroy() {
    this.$socket.client.off("chat-status");
    this.$socket.client.off("admin-status");
    this.$socket.client.off("teacher-status");
    window.removeEventListener("resize", this.detectViewPortListener);
  },
  methods: {
    moment,
    someoneConnected(event, who, id) {
      const userList = [...this.settings.connected[who]];
      if (
        event.toLowerCase().includes("online") &&
        userList.indexOf(id) === -1
      ) {
        //console.info({event,who,id,return:(userList).concat([id]),level:"online"});
        return userList.concat([id]);
      } else {
        if (event.toLowerCase().includes("offline")) {
          //console.info({event,who,id,return:userList.filter(user=>user!=id),level:"offline"});
          return userList.filter((user) => user != id);
        } else {
          //console.info({event,who,id,return:userList,level:"other"});
          return userList;
        }
      }
    },
    onReady(streamm) {
      const audioURL = window.URL.createObjectURL(streamm);
      this.stream = audioURL;
    },

    onEnable() {
      this.recorder = new WebRecording();
      this.recorder.onStop(this.onReady);
    },
    onStart() {
      this.isStarted = true;
      this.recorder.startRecord();
    },
    onStop() {
      this.isStarted = false;
      this.recorder.stopRecord();
    },

    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.feedback = {
        categories: [],
        uploadFiles: [],
      };
    },

    beforeUploadFiles(file) {
      this.feedback.uploadFiles.push(file);
    },

    removeFile(file) {
      this.feedback.uploadFiles = this.feedback.uploadFiles.filter(
        (f) => f.uid != file.uid
      );
    },

    uploadImage(options) {
      const { onSuccess, onError, file, onProgress } = options;
      setTimeout(() => {
        onSuccess(null, file);
      }, 200);
    },

    sendFeedback() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.feedbackLoading = true;
          const building = this.settings.image.find(
            (b) => b.db == this.settings.activeBuilding
          );

          const data = new FormData();
          data.append("userName", this.user.userName || "Anonyme");
          data.append("buildingId", this.settings.activeBuildingId);
          data.append("clientName", building.name);

          if (this.feedback.desc) data.append("desc", this.feedback.desc);

          data.append("categories", JSON.stringify(this.feedback.categories));

          if (this.feedback.section)
            data.append("section", this.feedback.section);

          if (this.feedback.priority)
            data.append("priority", this.feedback.priority);

          if (this.stream) {
            const d = new Blob(this.recorder.chunks, {
              type: "audio/mp3;codecs=opus",
            });
            data.append("audio", d, "audio.mp3");
          }

          this.feedback.uploadFiles.forEach((file) => {
            data.append("images", file);
          });

          apiClient
            .put("/managerFeedbacks", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            })
            .then((res) => {
              this.$message.success(this.$t("feedback.successSent"));
              this.feedback = {
                categories: [],
                uploadFiles: [],
              };
              this.onClose();
            })
            .catch((e) => {
              this.$message.error(this.$t("error.erreur"));
              onClose();
              console.error(e.stack);
              this.feedback = {
                categories: [],
                uploadFiles: [],
              };
            })
            .finally(() => {
              this.feedbackLoading = false;
              this.uploadPercentage = 0;
            });
        }
      });
    },

    reload() {
      this.reloads = this.reloads + 1;
    },
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen;
      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "isMobileMenuOpen",
        value,
      });
    },
    changeSetting: function (setting, value) {
      this.$store.commit("settings/CHANGE_SETTING", { setting, value });
    },
    setViewPort: function (isMobileView = false, isTabletView = false) {
      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "isMobileView",
        value: isMobileView,
      });
      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "isTabletView",
        value: isTabletView,
      });
    },
    detectViewPortListener: function () {
      this.detectViewPort(false);
    },
    detectViewPort: function (firstLoad = false) {
      const isMobile = this.settings.isMobileView;
      const isTablet = this.settings.isTabletView;
      const width = window.innerWidth;
      const state = {
        next: {
          mobile: width < 768,
          tablet: width < 992,
          desktop: !(width < 768) && !(width < 992),
        },
        prev: {
          mobile: isMobile,
          tablet: isTablet,
          desktop: !isMobile && !isTablet,
        },
      };
      // desktop
      if (
        state.next.desktop &&
        (state.next.desktop !== state.prev.desktop || firstLoad)
      ) {
        this.setViewPort(false, false);
      }
      // tablet & collapse menu
      if (
        state.next.tablet &&
        !state.next.mobile &&
        (state.next.tablet !== state.prev.tablet || firstLoad)
      ) {
        this.setViewPort(false, true);
        this.$store.commit("settings/CHANGE_SETTING", {
          setting: "isMenuCollapsed",
          value: true,
        });
      }
      // mobile
      if (
        state.next.mobile &&
        (state.next.mobile !== state.prev.mobile || firstLoad)
      ) {
        this.setViewPort(true, false);
      }
    },
    bindMobileSlide() {
      // mobile menu touch slide opener
      const unify = (e) => {
        return e.changedTouches ? e.changedTouches[0] : e;
      };
      document.addEventListener(
        "touchstart",
        (e) => {
          const x = unify(e).clientX;
          this.touchStartPrev = x;
          this.touchStartLocked = x > 70;
        },
        { passive: false }
      );
      document.addEventListener(
        "touchmove",
        (e) => {
          const x = unify(e).clientX;
          const prev = this.touchStartPrev;
          if (x - prev > 50 && !this.touchStartLocked) {
            this.toggleMobileMenu();
            this.touchStartLocked = true;
          }
        },
        { passive: false }
      );
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
