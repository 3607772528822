var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomRight"}},[_c('div',{class:_vm.$style.dropdown},[_c('span',{staticClass:"text-uppercase d-flex align-items-center"},[_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"width":"20","src":`resources/images/flags/${
          _vm.language == 'en'
            ? 'united-states'
            : _vm.language == 'fr'
            ? 'france'
            : 'tunisia'
        }.svg`,"alt":"Arabic"}}),_c('span',{class:_vm.$style.languageText},[_vm._v(_vm._s(_vm.$t("language." + _vm.language)))])])]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.selectLocale},slot:"overlay"},[_c('a-menu-item',{key:"en-US"},[_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"width":"20","src":"resources/images/flags/united-states.svg","alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language.en"))+" ")]),_c('a-menu-item',{key:"fr-FR"},[_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"width":"20","src":"resources/images/flags/france.svg","alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language.fr"))+" ")]),_c('a-menu-item',{key:"ar-AR"},[_c('img',{staticStyle:{"border-radius":"30%"},attrs:{"width":"20","src":"resources/images/flags/tunisia.svg","alt":"Arabic"}}),_vm._v(" "+_vm._s(_vm.$t("language.ar"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }