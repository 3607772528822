<template>
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <!-- <span v-if="menuInfo.version == 'v1'" class="badge badge-danger mr-2"
        >_
      </span> -->

      <span :class="styles.title">{{ $t(menuInfo.title) }}</span>

      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
    <template v-for="item in menuInfo.children">
      <item
        v-if="
          verifyRole(item) &&
          verifyBuildingAccess(item) &&
          verifyBulletinVersion(item)
        "
        :menu-info="item"
        :styles="styles"
        :key="item.key"
      />
      <sub-menu
        v-if="item.children"
        :menu-info="item"
        :styles="styles"
        :key="item.key"
      />
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from "ant-design-vue";
import Item from "./item";
import { mapGetters, mapState } from "vuex";

export default {
  name: "SubMenu",
  components: { Item },
  isSubMenu: true,

  props: {
    ...Menu.SubMenu.props,
    menuInfo: Object,
    styles: Object,
  },
  computed: {
    ...mapState(["settings", "user"]),
    ...mapGetters("user", ["user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
    getSchoolarYear() {
      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );
      return { start, end };
    },
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    verifyRole: function (item) {
      const existing = this.user.access[item.accessGroup];
      if (existing == undefined) return false;
      // console.log("existing : ", existing);
      if (
        existing === true ||
        (existing && existing.includes("all")) ||
        existing[0] == "true" // home passed from bool to array (client won't lose)
      )
        return true;
      const required = item.access;
      // console.info(item.key, required);
      if (
        required.some(
          (val) => existing === true || (existing && existing.includes(val))
        )
      )
        return true;
      else return false;
    },
    verifyBuildingAccess: function (item) {
      if (!item.buildingAccess) return true;
      else {
        const buildingAccess = item.buildingAccess;
        if (this.schoolDetails.access.edManager[buildingAccess]) return true;
        else return false;
      }
    },
    verifyBulletinVersion(item) {
      if (!item.version) return true;
      const currentSYEnd = new Date(this.getSchoolarYear.end);
      const version1End = new Date("2024-08-31T23:59:59.999Z"); // End of 31st August 2024
      const version = currentSYEnd < version1End ? "v1" : "v2";
      return item.version == version;
    },
  },
};
</script>
