<template>
  <div>
    <a-select
      show-search
      :autoFocus="true"
      placeholder="Année scolaire"
      option-filter-prop="children"
      :class="[$style.aSelect]"
      :filter-option="filterOption"
      @change="handleChange"
      :default-value="settings.activeSchoolarYear"
      v-model="settings.activeSchoolarYear"
    >
      <a-select-option
        :value="schoolarYear._id"
        v-for="schoolarYear in settings.schoolarYears"
        :key="schoolarYear._id"
      >
        {{ schoolarYear.name }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
/* eslint-disable */
import store from "store";
import { mapState } from "vuex";
import CuiSidebar from "@/components/cleanui/layout/Sidebar";
export default {
  name: "SchoolarYearLayout",
  components: { CuiSidebar },
  computed: mapState(["settings"]),
  methods: {
    handleChange(value) {
      store.set("activeSchoolarYear", value);
      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "activeSchoolarYear",
        value: value,
      });
      window.location.reload();
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style module>
.aSelect {
  width: 350px;
}

@media screen and (max-width: 1500px) {
  .aSelect {
    width: 300px !important;
  }
}
@media screen and (max-width: 1100px) {
  .aSelect {
    width: 230px !important;
  }
}
</style>
